import { useEffect } from "react";

function Home() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://ajax.googleapis.com/ajax/libs/model-viewer/3.4.0/model-viewer.min.js";
    script.type = "module";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="">
      <model-viewer
        style={{ height: "100vh", width: "100%", backgroundColor: "rgba(29,29,29,1)" }}
        alt="A sleek device attached to your plant’s soil to monitor humidity and automatically control irrigation."
        src="/book1.glb"
        // ar
        shadow-intensity="10"
        camera-controls
        auto-rotate
        touch-action="pan-y"
      />
    </div>
  );
}

export { Home };
