import "../../../../styles/global.css";
import "./style.css";


function BuyLink() {
  return (
    <>
        <div className="flexCol" style={{gap: '10px'}}>
            <a href="https://www.crossmint.com/collections/journal-of-unforgetfulness-volume-i/drop" target="_blank" rel="noreferrer"><button className="mainBtn">Buy NFT</button></a>
        </div>
    </>  
  );
}

export { BuyLink };
