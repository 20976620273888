import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { Nft } from "./pages/nft";


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/nft" element={<Nft />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
