import PocketBase from "pocketbase";
import { useMemo, useState } from "react";
import "../../styles/global.css";
import { acquireModes, steps } from "./info/dictionaries";
import { HasBoughtOnRelease } from "./steps/1.has-bought-on-release";
import { EmailAndWord } from "./steps/2.1.email-and-word";
import { BuyLink } from "./steps/2.2.buy-link";
import { CommingSoon } from "./steps/comming-soon";
import "./style.css";

const pb = new PocketBase('https://journal-of-unforgetfulness-back-end.fly.dev');


export function Nft() {
    const [currentStep, setCurrentStep] = useState(steps.hasBoughtOnRelease)

    const context = useMemo(() => ({
        currentStep,
        setCurrentStep,
        pb
    }), [currentStep, setCurrentStep])

    const currentStepElement = useMemo(() => {
        const stepToElementMapping = {
            [steps.hasBoughtOnRelease]: <HasBoughtOnRelease context={context} acquireModes={[acquireModes.mint, acquireModes.buy]} />,
            [steps.emailAndWord]: <EmailAndWord context={context} />,
            [steps.safetyDisclaimer]: <CommingSoon context={context} />,
            [steps.buyLink]: <BuyLink context={context} />,
        }

        return stepToElementMapping[currentStep]
    }, [context, currentStep])




  return (
    <div className="grayBg" style={{width: '100vw', height: '100vh', display: 'flex', gap: "20px", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{color: 'white'}}>Mint your NFT!</h1>  
        <div className="box" style={{color: 'white'}}>
           {currentStepElement}
        </div>
    </div>  
  );
}

