import PocketBase from "pocketbase";
import { useState } from "react";
import bookWord from "../../../../assets/book-word.png";
import "../../../../styles/global.css";
import "./style.css";

const pb = new PocketBase('https://journal-of-unforgetfulness-back-end.fly.dev');


function EmailAndWord() {
    const [word, setWord] = useState("")
    const [email, setEmail] = useState("")
    const [completePhrase, setCompletePhrase] = useState("")


    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [error, setError] = useState("")
    const [alreadyUsed, setAlreadyUsed] = useState(false)
    const [isValidWord, setIsValidWord] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const isButtonDisabled = !word || isLoading || !email

    function reset() {
        setError("")
        setAlreadyUsed(false)
        setIsValidWord(false)
    }

    async function upsertMintAttempt() {
        try {
            const record = await pb.collection('minters').getFirstListItem(`email="${email}"`);

            const currentAttempts = record.number_of_atempts

            const data = {
                ...record,
                number_of_atempts: currentAttempts + 1,
                last_attempt: new Date().toISOString()
            };
            
            await pb.collection('minters').update(record.id, data);

        } catch(e) {
            if (e.status === 404) {
                const data = {
                    email,
                    "number_of_atempts": 1,
                    last_attempt: new Date().toISOString(),
                    word
    
                };
                
                await pb.collection('minters').create(data);
            } else {
                throw new Error({status: 500, message: "DB error"})
            }
        }
    }

    async function checkWord() {
        reset()
        setIsLoading(true)

        try {
            const record = await pb.collection('random_words').getFirstListItem(`word="${word}"`);   

            if (record.was_used) {
                setAlreadyUsed(true)
            } else {
                await upsertMintAttempt()
                setIsValidWord(true)
            }
        } catch (e) {
            console.log({e})
            if (e.status === 404) {
                setError("Invalid word")
            } else {
                setError("Unkown error: Try again later or please contact me! My email: lepcbelisario@gmail.com")
            }
        }

        setIsLoading(false)
    }

    function updateWord(newWord) {
        setError("")
        setAlreadyUsed(false)
        setWord(newWord)
    }


  return (
    <>
        <div className="flexCol" style={{gap: '10px'}}>
            {/* Word */}
            <p>
                <label htmlFor="your-word">
                    Type the word printed on your book 
                </label>
                {" "}
                <span 
                    className="info" 
                    onClick={() => setOpenInfoModal(!openInfoModal)} 
                >
                    &#9432;
                </span>
            </p>
            <input 
                type="text" 
                id="your-word" 
                style={{padding: "5px", backgroundColor: "rgba(255, 255, 255, 0.25)", borderRadius: "10px", color: "white"}} 
                className={error || alreadyUsed ? "error" : ""}
                onChange={(e) => updateWord(e.target.value)}
                value={word}
            />
            {/* Email */}
            <p>
                <label htmlFor="your-word">
                    Type your email
                </label>
            </p>
            <input 
                type="text" 
                id="your-word" 
                style={{padding: "5px", backgroundColor: "rgba(255, 255, 255, 0.25)", borderRadius: "10px", color: "white"}} 
                className={error || alreadyUsed ? "error" : ""}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <button 
                style={{padding: "10px 50px", backgroundColor: "rgba(255, 255, 255, 0.5)", borderRadius: "10px", color: "white"}} 
                disabled={isButtonDisabled}
                onClick={checkWord}
                className="button flexCol"
            >
                {isLoading ? <div class="lds-ring"><div></div><div></div><div></div><div></div></div> : 'Mint'}
                
            </button>
            <p className="disclaimer">If your code and email are correct a pop up will open, follow the instruction on this popup until you mint the NFT. After that you <b>need to access</b> <a href="https://www.crossmint.com/signin?callbackUrl=/user/collection">this page</a> and login using your <b>email</b></p>
            {error && <span style={{color: 'red'}}>{error}</span>}
            {alreadyUsed && <span style={{color: 'red'}}>This code was already used</span>}
        </div>
        {openInfoModal && <div className="modal flexCol">
            <span style={{position: "absolute", top: '10px', left: "10px", fontSize: "25px", cursor: "pointer"}} onClick={() => setOpenInfoModal(false)}>&#10006;</span>
            <img src={bookWord} style={{width: '100%', display: 'block', zIndex: 100}}/>
        </div>}
        {isValidWord && <div className="modal">
            <span style={{position: "absolute", top: '10px', left: "10px", fontSize: "25px", cursor: "pointer", color: "black"}} onClick={() => setIsValidWord(false)}>&#10006;</span>
            <embed type="text/html" src="https://www.crossmint.com/collections/journal-of-unforgetfulness-volume-i/claim" width="100%" height="100%" />
        </div>}
    </>  
  );
}

export { EmailAndWord };
