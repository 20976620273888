import PocketBase from "pocketbase";
import "../../../../styles/global.css";
import { steps } from "../../info/dictionaries";
import "./style.css";

const pb = new PocketBase('https://journal-of-unforgetfulness-back-end.fly.dev');

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const acquireModesToButtonTitle = {
    mint: "Bought on launch event *",
    buy: "Did not buy on launch event",
}

const acquireModeToNextStepMap = {
    mint: steps.emailAndWord,
    buy: steps.buyLink,
}

function HasBoughtOnRelease({acquireModes, context}) {
    const {setCurrentStep} = context

  return (
    <div className="flexCol" style={{gap: '10px'}}>
        <h2 style={{marginBottom: "10px"}}>How did you acquire the book?</h2>
        <div className="flexRow" style={{gap: "15px"}}>
            {acquireModes.map(mode => (
                <button className="mainBtn" onClick={() => setCurrentStep(acquireModeToNextStepMap[mode])}>
                    {capitalizeFirstLetter(acquireModesToButtonTitle[mode])}
                </button>
            ))}
        </div>
        <p className="disclaimer" style={{fontSize: '13px', marginTop: '25px', borderRadius: "5px"}}>* The official book launch took place in <b>2024.03.07</b> at <b>Belo Horizonte, MG - Brazil</b></p>
    </div>
  );
}

export { HasBoughtOnRelease };
