export const acquireModes = {
    mint: "mint",
    buy: "buy"
}

export const steps = {
    hasBoughtOnRelease: "hasBoughtOnRelease",
    emailAndWord: "emailAndWord",
    buyLink: "buyLink",
    safetyDisclaimer: "safetyDisclaimer" 
}


